import Image from "components/Image"
import Link from "next/link"
import isRichTextValid from "utilities/isRichTextValid"
import richText from "utilities/richText"
import storyblokImageLoader from "utilities/storyblokImageLoader"

export default function Card({ image, slug, name, formatted_address }) {
  return (
    <div className="border-0.5 border-grey-cool bg-white h-full flex flex-col">
      <Link href={slug}>
        <Image
          loader={image?.filename && image?.filename !== "" ? storyblokImageLoader : undefined}
          src={image?.filename && image?.filename !== "" ? image.filename : "/assets/placeholder.png"}
          alt={image?.alt ?? ""}
          placeholder={image?.blurDataURL ? "blur" : "empty"}
          blurDataURL={image?.blurDataURL}
          width={299}
          height={153}
          className="w-full aspect-[2/1] object-cover"
          sizes="(max-width: 1024px) 45vw, (max-width: 640px) 95vw, 20vw"
        />
      </Link>
      <div className="py-7 px-4 xl:px-7 flex flex-col h-full">
        <Link className="bracket-link relative block w-full mr-8" href={slug}>
          <h2 className="text-m2 font-semibold text-primary text-center">{name}</h2>
        </Link>
        {isRichTextValid(formatted_address) ? (
          <div className="pt-6 text-center prose-p:pb-0 flex flex-col justify-center grow">
            {richText(formatted_address)}
          </div>
        ) : null}
      </div>
    </div>
  )
}
