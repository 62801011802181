import { useState } from "react"
import cn from "classnames"
import CallToAction from "components/CallToAction"
import { useRequestSending } from "utilities/useRequestSending"

export function CardListSection({
  Card,
  backgroundColor = "white",
  items,
  total,
  perPage,
  currentPage,
  category,
  columns,
  handler
}) {
  const [itemList, setItemList] = useState(items)
  const [page, setPage] = useState(currentPage)
  const isEnd = page * perPage - total >= 0;

  const { send, isSending } = useRequestSending(async () => handler({
    page: page + 1,
    perPage,
    category
  }),
    {
      onSuccess({ items }) {
        setItemList((i) => [...i, ...items])
        setPage((p) => p + 1)
      }
    });

  return <section className={`px-5 bg-${backgroundColor}`}>
    <div className="max-w-screen-xl mx-auto flex flex-col justify-center items-center pb-8 w-full">
      <div
        className={cn("grid sm:grid-cols-2 gap-8 py-8 w-full", {
          "lg:grid-cols-4": columns === 4,
          "lg:grid-cols-3": columns === 3,
        })}
      >
        {itemList?.map((item) => (
          <Card {...item} key={item.name + (item.slug || '')} />
        ))}
      </div>
      {!isEnd && (
        <CallToAction
          style="ghost"
          button
          disabled={isSending}
          loading={isSending}
          onClick={() => send()}
        >
          Load More
        </CallToAction>
      )}
    </div>
  </section>

}