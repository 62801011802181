import { isNil, omitBy } from "lodash";
import { stringify } from "qs";

export function makeQuery(url, data) {
  return data !== null
    ? url +
        (data
          ? "/?" +
            stringify(
              omitBy(
                data,
                (v) => isNil(v) || v === ""
              )
            )
          : "")
    : url;
}
