import { getGlobals, getArchive } from "storyblok/api"
import Meta from "components/Meta"
import { getArchiveOffices } from "storyblok/offices"
import SimpleArchiveSection from "components/Archive/SimpleArchiveSection"
import OfficeCard from "components/OfficeCard"
import ArchiveHeader from "components/Archive/ArchiveHeader"
import { CardListSection } from "components/Archive/CardListSection"
import { getArchiveOfficesApi } from "storyblok/officesApi"

export default function TeamMemberArchive({ meta, story, items, perPage, total, currentPage }) {
  return (
    <>
      <Meta info={meta} />
      <main data-pagefind-ignore="all">
        <ArchiveHeader story={story?.content} breadcrumbs={[{ text: "Locations Served", href: "/offices" }]} />
        <SimpleArchiveSection
          list={
            <CardListSection
              items={items}
              total={total}
              perPage={perPage}
              currentPage={currentPage}
              columns={4}
              handler={(params) => getArchiveOfficesApi(params)}
              Card={OfficeCard}
            />
          }
        />
      </main>
    </>
  )
}

export async function getStaticProps({ preview = null }) {
  const globals = await getGlobals()

  const story = await getArchive("offices", preview)

  const { items, currentPage, total, perPage } = await getArchiveOffices()

  return {
    props: {
      story: story ?? null,
      meta: story?.content?.twentysixty_seo ?? null,
      items,
      currentPage,
      total,
      perPage,
      globals: globals ?? null,
      preview,
    },
  }
}
