import { useCallback, useEffect, useRef, useState } from "react";

/**
 * 
 * @param {(...args: any[]) => Promise<any>} callback 
 * @param {{
 * onEnd?: () => void,
 * onStart?: () => void,
 * onError?: (error: Error) => void,
 * onSuccess: (...args: any[]) => void,
 * onAfterSuccess: (...args: any[]) => void,
 * }} props 
 * @returns 
 */
export function useRequestSending(
  callback,
  props,
) {
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const runAfterSuccessRef = useRef(false);
  const propsRef = useRef(props);

  propsRef.current = {
    extractDataHandler: props?.extractDataHandler
      ? props?.extractDataHandler
      : (rawData) => rawData,
    ...props,
  };

  const send = useCallback(
    async (...args) => {
      setIsSending(true);
      setError(null);
      setData(null);
      propsRef.current?.onStart && propsRef.current?.onStart();
      try {
        const dbody = await callback(...args);

        let data;

        const extractDataHandler = propsRef.current?.extractDataHandler
          ? propsRef.current?.extractDataHandler
          : (rawData) => rawData;

        data = extractDataHandler(dbody);

        propsRef.current?.onSuccess && propsRef.current.onSuccess(data);
        setData(data);
        runAfterSuccessRef.current = true;

        return true;
      } catch (error) {
        propsRef.current?.onError && propsRef.current.onError(error);
        setError(error);
        return false;
      } finally {
        setIsSending(false);
        propsRef.current?.onEnd && propsRef.current?.onEnd();
      }
    },
    [callback],
  );

  useEffect(() => {
    if (!runAfterSuccessRef.current) return;
    runAfterSuccessRef.current = false;
    propsRef.current?.onAfterSuccess &&
      propsRef.current?.onAfterSuccess(data);
  }, [data]);

  return { send, isSending, error, data };
}
