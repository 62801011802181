import PageHeader from "components/DynamicComponent/molecules/PageHeader"
import StoryblokVisualEditor from "components/StoryblokVisualEditor"

export default function ArchiveHeader({ story, breadcrumbs }) {
  return (
    <StoryblokVisualEditor story={story}>
      <PageHeader
        blok={
          story?.page_header?.length
            ? { ...story?.page_header[0], breadcrumbs }
            : {
                type: "standard",
                heading: story?.heading,
                breadcrumbs,
              }
        }
      />
    </StoryblokVisualEditor>
  )
}
