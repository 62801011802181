import { makeQuery } from "utilities/makeQuery"

/**
 * 
 * @param {{page?: number, perPage?: number}} params 
 * @returns 
 */
export async function getArchiveOfficesApi(params) {
  const res = await fetch(makeQuery('/api/archive-offices', params))

  return await res.json()
}