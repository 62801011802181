import dynamic from "next/dynamic"

const Component = {
  standard: dynamic(() => import("components/DynamicComponent/molecules/PageHeader/StandardPageHeader")),
  gradient: dynamic(() => import("components/DynamicComponent/molecules/PageHeader/GradientPageHeader")),
}

export default function PageHeader({ blok }) {
  const Header = Component[blok.type]
  return <Header blok={blok} />
}
